<template>
    <button
        :type="type"
        :class="{ secondary: isSecondaryColor, inline: isInline, active }"
        :disabled="disabled"
        @click="$emit('click')"
    >
        {{ text }}
    </button>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            default: 'button'
        },
        text: {
            type: String,
        },
        isSecondaryColor: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        isInline: {
            type: Boolean,
            default: false
        },
        active: {
            type: Boolean,
            default: false
        }
    },
    emits: ['click']
}
</script>

<style lang="scss" scoped>
button {
    @include text-button;

    &.inline {
        padding: 0 0.25rem;
        min-height: auto;
        height: auto;
        display: inline-block;
    }
}
</style>
